import PropTypes from "prop-types"

import {
  Button,
  Stack,
  HeadingTwo,
  Link,
  Text,
  TextLG,
  HeroHeadingTwo,
  Image,
  Flex,
  TextXS,
  Box,
  Grid,
  HeadingFour,
  useMediaQuery,
} from "datastory-shared/ui"
import { Section, Listing } from "datastory-shared/sections"

const Home = ({ services }) => {
  const featuredDatastories = services.slice(0, 10)

  const [isMobile] = useMediaQuery("(max-width: 768px)")

  return (
    <>
      <Section py="xl">
        <Stack
          w={["auto", null, null, "container.xxl"]}
          alignItems="center"
          spacing="md"
          textAlign="center"
        >
          <HeroHeadingTwo
            w={["auto", null, null, "container.lg"]}
            whiteSpace={["unset", null, null, "pre-line"]}
          >
            We’re {""}
            <HeroHeadingTwo as="span" color="blurble.400">
              8 billion {""}
            </HeroHeadingTwo>
            {`people on earth. \n This is our story to tell.`}
          </HeroHeadingTwo>

          <TextLG w={["auto", null, "container.md"]}>
            Datastory.org is a space for learning about the world through open
            data, visualization and data storytelling — to better navigate the
            complexities of the 21st century.
          </TextLG>
        </Stack>
      </Section>

      <Flex
        justifyContent="center"
        alignItems="center"
        direction={["column", null, "row"]}
        gap="lg"
        bg="#F8F6F4"
        px={["sm", null, "xl"]}
        pt={0}
        pb="xl"
      >
        {isMobile && (
          <Image
            src="/static/images/illustrations/color_tool.png"
            alt="color tools"
            width={200}
            height={150}
          />
        )}
        <Stack
          spacing="md"
          w={["auto", null, "container.sm"]}
          pb="sm"
          pt={[0, null, "xl"]}
        >
          <HeadingTwo>Datastory.org relaunches in 2025.</HeadingTwo>
          <TextLG>
            Our new website is part of ongoing improvements at Datastory.org!
            While we work on the final version, our current focus is
            strengthening Datastory Platform for all data storytellers and
            running Datastory Academy in schools. 2025 will see the full launch
            of our training programs in data journalism, more stories on the
            site, and expanded data sources.
          </TextLG>
        </Stack>
        {!isMobile && (
          <Image
            src="/static/images/illustrations/color_tool.png"
            alt="color tools"
            width={350}
            height={150}
          />
        )}
      </Flex>

      <Flex w="100%" justifyContent="center" py="xl" px="sm">
        <Stack w={["auto", null, "container.md"]} spacing="md">
          <HeadingTwo textAlign="center">
            What is Datastory doing in the meantime?
          </HeadingTwo>
          <TextLG>
            {`We're currently building Datastory Platform, our data visualisation 
            ecosystem that empowers organizations and storytellers to create 
            impactful data-driven narratives using both internal and open data. 
            When we relaunch Datastory.org in 2025, we'll be building on this 
            strengthened foundation to create new Data Stories faster than ever*. 
            Our focus remains on making this platform robust enough to support 
            data storytellers worldwide while maintaining our educational programs.`}
          </TextLG>
          <br />
          <TextLG>
            {`While we'd love to tell you more about Datastory Platform, it's a bit
          awkward promoting the for-profit part of organization on the non-profit
          site, so you'll have to head over to datastory.tech to hear our
          unrestrained pitch.`}
          </TextLG>
          <TextXS>
            {`*We couldn't find an actual speed record for building data stories,
          but we're pretty confident this could set that bar.`}
          </TextXS>{" "}
          <Link
            href="https://datastory.tech"
            isExternal
            variant="standalone"
            textAlign="center"
          >
            <Button
              variant="outline"
              size="md"
              colorScheme="blurble"
              px="lg"
              mt="md"
            >
              Visit Datastory.tech
            </Button>
          </Link>
        </Stack>
      </Flex>

      <Section maxW="container.xl" bg="#F8F6F4">
        <Stack
          spacing="md"
          textAlign="center"
          w={["auto", null, "container.xxl"]}
          pb="sm"
          alignItems="center"
        >
          <HeadingTwo>Tools from Datastory.org</HeadingTwo>
          <TextLG w={["auto", null, "container.md"]}>
            Learn something new with our data-driven tools. Our data stories are
            updated automatically as new data becomes available.
          </TextLG>

          <Link variant="standalone" href="/services" pt="md">
            <Button variant="solid" size="md" colorScheme="blurble" px="lg">
              Browse all
            </Button>
          </Link>
        </Stack>
        <Listing data={featuredDatastories} type="services" />
      </Section>

      <Section py="xl" px="0" w="container.xxl">
        <Stack
          spacing="md"
          w={["auto", null, "container.xxl"]}
          pb="sm"
          textAlign="center"
          alignItems="center"
        >
          <HeadingTwo>Participate in upcoming projects</HeadingTwo>
          <TextLG w={["auto", null, "container.md"]}>
            With the continued development of Datastory Platform throughout
            2024, we’re preparing to take on more non-profit collaborations in
            2025.
          </TextLG>
        </Stack>

        <Grid variant="12">
          <Box
            height="100%"
            border="2px solid"
            borderColor="gray.100"
            borderRadius="md"
            p="lg"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
          >
            <Stack spacing="md">
              <HeadingFour>🎓 Datastory Academy</HeadingFour>
              <Text>
                Join one of our upcoming courses for youth in data journalism
                and learn how to filter and analyze datasets, distill insights
                and create engaging and impactful visualizations. Starting
                February 2025 in Stockholm schools, with plans to expand
                globally.
              </Text>
              <Link
                href="/academy/signup"
                variant="standalone"
                textAlign="center"
              >
                <Button variant="solid" size="md" colorScheme="blurble" px="lg">
                  Sign up
                </Button>
              </Link>
            </Stack>
          </Box>

          <Box
            height="100%"
            border="2px solid"
            borderColor="gray.100"
            borderRadius="md"
            p="lg"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
          >
            <Stack spacing="md">
              <HeadingFour>
                🚀 Open data and research collaborations{" "}
              </HeadingFour>
              <Text>
                Does your organization want to do good with data? Do you have an
                idea for a collaboration with Datastory.org? Don’t hesitate to
                get in touch with us!
              </Text>
            </Stack>
            <Link
              variant="standalone"
              textAlign="center"
              href="mailto:hello@datastory.org?subject=Inquiry to Datastory"
            >
              <Button variant="solid" size="md" colorScheme="blurble" px="lg">
                Contact us
              </Button>
            </Link>
          </Box>
        </Grid>
      </Section>
    </>
  )
}

Home.propTypes = {
  services: PropTypes.array,
}

export default Home
